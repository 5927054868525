<template>
	<div class="infoForm">
		<div class="title">{{forType==1?'资料确认':'个人信息资料'}}</div>
		<div class="container_box_item">
			<div class="item_label"><span style="color:red;">*</span>所属街道：</div>
			<div class="item">
				<div class="inp" @click="onOpen('diqu')" v-if="value.length === 0">
					请选择所属街道
				</div>
				<div class="inp" @click="onOpen('diqu')" v-if="value.length !== 0">
					{{ value }}
				</div>
			</div>
		</div>
		<div class="container_box_item">
			<div class="item_label"><span style="color:red;">*</span>所属学校：</div>
			<div class="item">
				<div class="inp" @click="onOpen('school')" v-if="value.length === 0" style="width: 180px;">
					请选择所属学校
				</div>
				<div class="inp" @click="onOpen('school')" v-if="value.length !== 0" style="width: 180px;overflow: scroll;">
					{{ schoolValue }}
				</div>
			</div>
		</div>
		<div v-if="levelType==1" class="container_box_item">
			<div class="item_label"><span style="color:red;">*</span>所属年级：</div>
			<div class="item">
				<div class="inp" @click="onOpen('school')" v-if="value.length === 0" style="width: 180px;">
					请选择所属年级
				</div>
				<div class="inp" @click="onOpen('school')" v-if="value.length !== 0" style="width: 180px;overflow: scroll;">
					{{ gradeValue }}
				</div>
			</div>
		</div>
		<div v-if="levelType==1" class="container_box_item">
			<div class="item_label"><span style="color:red;">*</span>所属班级：</div>
			<div class="item">
				<div class="inp" @click="onOpen('class')" v-if="value.length === 0" style="width: 180px;">
					请选择所属班级
				</div>
				<div class="inp" @click="onOpen('class')" v-if="value.length !== 0" style="width: 180px;overflow: scroll;">
					{{ classValue }}
				</div>
			</div>
		</div>
		
		<div class="container_box_item">
			<div class="item_label"><span style="color:red;">*</span>{{levelType==2?'教师姓名':'学生姓名'}}：</div>
			<div class="item">
				<input type="text" class="inp" placeholder="请输入您的姓名" v-model="nickname" :readonly="btnFlag">
			</div>
		</div>
		<div class="container_box_item" v-if="levelType==1">
			<div class="item_label"><span style="color:red;">*</span>性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</div>
			<div class="item">
				<div class="inp" @click="onOpen('sex')" v-if="value.length === 0">
					请选择性别
				</div>
				<div class="inp" @click="onOpen('sex')" v-if="value.length !== 0">
					{{ studentSex }}
				</div>
			</div>
		</div>
		
		<div class="container_box_item" v-if="levelType==1">
			<div class="item_label"><span style="color:red;">*</span>指导教师姓名：</div>
			<div class="item">
				<input type="text" class="inp" placeholder="请输入教师的姓名" v-model="teacherName" :readonly="btnFlag">
			</div>
		</div>
		
		<div class="container_box_item">
			<div class="item_label"><span v-if="levelType==2" style="color:red;">*</span>{{levelType==2?'联系电话':'指导教师电话'}}：</div>
			<div class="item">
				<input type="text" class="inp" placeholder="请输入联系电话" v-model="mobile" :readonly="btnFlag">
			</div>
		</div>
		
		<div class="container_box_ti_btn" :style="levelType==2?'margin-top: 100px;':''" v-if="forType!=1">
			<van-button class="btn_style btn_32" color="#FFA028" @click="handleClick('comfiirm')"
				style="letter-spacing: 3px;">
				开始答题
			</van-button>
		</div>
		
		<div class="container_box_ti_btn" style="flex-direction: row;justify-content: center;" :style="levelType==2?'margin-top: 100px;':''" v-if="forType==1">
			<van-button v-if="!btnFlag" class="btn_style btn_32 btn_style_for" color="#36CED0" @click="handleClick('change')"
				style="letter-spacing: 0;">
				修&nbsp;&nbsp;&nbsp;改
			</van-button>
			<van-button class="btn_style btn_32 btn_style_for" color="#169B9D" @click="handleClick('comfiirmInfo')"
				style="letter-spacing: 0;">
				确&nbsp;&nbsp;&nbsp;认
			</van-button>
		</div>

		<van-popup v-model="schoolShow" round position="bottom" :safe-area-inset-bottom="true" @click-overlay="onClose">
			<van-picker title="请选择所属学校" show-toolbar :columns="levelType==2?columnsT:columns" @confirm="onConfirm" @cancel="onCancel"
				value-key="name" ref="picker" />
		</van-popup>
		
		<van-popup v-model="classShow" round position="bottom" :safe-area-inset-bottom="true" @click-overlay="onClose">
			<van-picker title="请选择所属班级" show-toolbar :columns="columnsC" @confirm="onConfirmClass" @cancel="onCancel"
				value-key="name" ref="picker" />
		</van-popup>
		
		<van-popup v-model="sexShow" round position="bottom" :safe-area-inset-bottom="true" @click-overlay="onClose">
			<van-picker title="请选择性别" show-toolbar :visible-item-count='2' :columns="sexList" @confirm="onConfirmSex" @cancel="onCancel"
				value-key="name" ref="picker" />
		</van-popup>
	</div>
</template>

<script>
	import {
		Dialog,
		Toast
	} from 'vant';
	import {
		getSchoolInfo,
		getClassList,
		getInfo,
		uploadData
	} from '../api';
	export default {
		name: "infoForm",
		data() {
			return {
				value: '请选择所属街道',
				schoolValue: '请选择所属学校',
				gradeValue: '请选择所属年级',
				classValue: '请选择所属班级',
				studentSex: '请选择性别',
				schoolShow: false,
				classShow: false,
				sexShow: false,
				formData: {
					user_id: '',//微信登录成功返回的数组对象中的用户id
					level: '',//学生或教师，传1为学生，2为教师
					area: '',//所属街区id
					school: '',//所属学校id
				},
				teacherName: '',//教师姓名
				nickname: '',
				gender: '',//性别
				grade: '',//年级id
				class: '',//班级id
				mobile: '',
				columns: [],
				columnsT: [],
				columnsC: [],
				sexList: ['男','女'],
				flag: false,
				type: '',
				register: '',
			}
		},
		props: {
			btnFlag: {
				type: Boolean,
				default: false,
			},
			levelType: {
				type: String,
				default: 1,
			},
			forType: {
				type: String,
				default: 1,
			}
		},
		created() {
			// this.levelType = 2
			this.get_SchoolInfo()
			if (localStorage.getItem('token') != null) {
				this.get_Info()
				this.formData.user_id = localStorage.getItem('user_id')
				console.log(localStorage.getItem('user_id'))
			}
		},
		methods: {
			get_Info() {
				Toast.loading({
					duration: 0, // 持续展示 toast
					forbidClick: true,
					message: '加载中...',
					overlay: true
				})
				getInfo({
					token: localStorage.getItem('token')
				}).then(res => {
					let data = res.data.data
					if(data.level!=0){
						this.levelType = data.level
					}
					this.register=data.is_register
					if(this.forType==1){
						if(data.is_register!=1){
							Toast.clear()
							Dialog.confirm({
									title: '提示',
									message: '未答题，请前往答题',
									showCancelButton: false,
								})
								.then(() => {
									this.$router.replace({
										name: 'Index',
									})
								})
						}
						// data.level = 2
						this.formData.level = data.level
						this.value = data.area.name
						this.schoolValue = data.school.name
						this.formData.area = data.area_id
						this.formData.school = data.school_id
						this.nickname = data.nickname
						if(data.level==1){
							this.studentSex = data.gender==1?'男':'女'
							this.gender = data.gender
							this.mobile = data.teacher_mobile
							this.teacherName = data.teacher
							this.gradeValue = data.grade.name
							this.grade = data.grade_id
							this.classValue = data.class.name
							this.class = data.class.id
						}else{
							this.mobile = data.mobile
						}
					}
					this.$forceUpdate();
					// console.log(this.btnFlag,'是否可以')
					// this.$emit('btnInfo', res.data.data.is_build)
					Toast.clear()
				})
			},
			onOpen(type) {
				// if(this.forType==1){
				// 	return false;
				// }
				if (type === 'diqu') {
					this.schoolShow = true
				} else if (type === 'school') {
					this.schoolShow = true
				}else if(type === 'class'){
					if(this.formData.area==''){
						this.$toast.fail('请先选择街区');return false;
					}else{
						this.classShow = true
					}
				}else{
					this.sexShow = true
				}
			},
			onClose() {
				this.sexShow = false
				this.schoolShow = false
				this.classShow = false
			},
			onConfirm(e, value) {
				this.formData.area = this.columns[value[0]].id
				this.formData.school = this.columns[value[0]].children[value[1]].id
				this.value = e[0]
				this.schoolValue = e[1]
				if(this.levelType==1){
					this.gradeValue = e[2]
					this.grade = this.columns[value[0]].children[value[1]].children[value[2]].id
					this.getClassInfo(this.columns[value[0]].children[value[1]].children[value[2]].id)
				}
				this.schoolShow = false
			},
			getClassInfo(id){
				let data = {
					pid: id
				}
				getClassList(data).then(res => {
					let data = res.data.data
					this.columnsC = data
				})
			},
			onConfirmClass(e,value){
				this.classValue = e.name
				this.class = this.columnsC[value].id
				this.classShow = false
			},
			onConfirmSex(e,value) {
				this.studentSex = e
				if(e=='男'){
					this.gender = 1
				}else{
					this.gender = 2
				}
				this.sexShow = false
			},
			onCancel() {
				this.schoolShow = false
				this.sexShow = false
				this.classShow = false
			},
			get_SchoolInfo() {
				getSchoolInfo().then(res => {
					let data = res.data.data
					this.columns = data
					let data1 = JSON.parse(JSON.stringify(res.data.data))
					for(var i=0;i<data1.length;i++){
						for(var a=0;a<data1[i].children.length;a++){
							data1[i].children[a].children = '';
						}
					}
					this.columnsT = data1
				})
			},
			handleClick(type) {
				let data = this.formData
				data.level = this.levelType
				if(data.area==''){
					this.$toast.fail('请选择街区');return false;
				}
				if(data.school==''){
					this.$toast.fail('请选择学校');return false;
				}
				if(this.nickname==''){
					// console.log(data)
					this.$toast.fail('请输入姓名');return false;
				}else{
					data.nickname = this.nickname
				}
				if(this.levelType==2){
					if(this.mobile==''){
						this.$toast.fail('请输入电话号码');
						return false;
					}else{
						data.mobile = this.mobile
					}
				}else{
					// console.log(this.mobile,'学生，有指导电话')
					if(this.mobile!=''){
						console.log(this.mobile)
						data.teacher_mobile = this.mobile
					}
					if(this.grade==''){
						this.$toast.fail('请选择年级');
						return false;
					}else{
						data.grade = this.grade
					}
					if(this.class==''){
						this.$toast.fail('请选择班级');
						return false;
					}else{
						data.class = this.class
					}
					if(this.gender==''){
						this.$toast.fail('请选择性别');
						return false;
					}else{
						data.gender = this.gender
					}
					
					if(this._.isEmpty(this.teacherName)){
						this.$toast.fail('请输入指导老师姓名');
						return false;
					}else{
						data.teacher = this.teacherName
					}
					
				}
				if(type=='comfiirm' || type=='change'){
					if(this.register!=0){
						Dialog.confirm({
							title: '提示',
							message: '个人信息仅能修改一次，请核对好个人信息！',
						})
						.then(() => {
							this.$emit('formClick', data,'change')
						})
					}
					else{
						this.$emit('formClick', data,type)
					}
				}else{
					this.$emit('formClick', data,type)
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.infoForm {
		background-color: #fff;

		.title {
			font-size: 40px;
			font-weight: bold;
		}

		.container_box_item {
			display: flex;
			align-items: center;
			margin: 30px 0px;
			width: 550px;
			height: 80px;
			background-color: #E8E8E8;
			border-radius: 15px;
			padding: 0px 20px;

			.item_label {
				font-size: 26px;
				font-weight: bold;
				span{
					display: none;
				}
			}

			img {
				width: 52px;
				height: 52px;
			}

			.item {
				display: flex;
				align-items: center;

				.inp {
					width: 280px;
					height: 60px;
					line-height: 60px;
					// border: 1PX solid #969495;
					// border-radius: 20px;
					padding-left: 10px;
					color: #969495;
					font-size: 26px;
				}

				.item_border {
					font-size: 28px;
					border: 1PX solid #969495;
					color: #969495;
					border-radius: 10px;
					padding: 10px 85px 10px 25px;
					margin-right: 24px;
					box-sizing: border-box;
				}

			}
		}

		.container_box_ti_btn {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			img {
				width: 408px;
			}
		}

		::v-deep .van-picker__toolbar {
			height: 44PX;
		}

		::v-deep .van-picker__cancel {
			font-size: 16PX;
		}

		::v-deep .van-picker__confirm {
			font-size: 16PX;
		}

		::v-deep .van-picker-column {
			font-size: 16PX;
		}

		::v-deep .van-ellipsis {
			overflow: visible;
		}

		::v-deep .van-ellipsis {
			white-space: pre-wrap;
		}

		.btn_style {
			width: 490px;
			height: 35PX;
			line-height: 80px;
			border: 5px solid #fff !important;
			color: #fff;
			font-weight: bolder;
			font-family: "微软雅黑";
			// letter-spacing: 1px;
			margin-top: 20px;
		}
		.btn_style_for{
			width: 280px;
			margin: 0px 10px;
		}

		.btn_37 {
			font-size: 37px !important;
		}

		.form_box {
			padding: 48px 24px 48px 25px;
			width: 100%;
			box-sizing: border-box;
		}

		.van-popup {
			border-radius: 10px;
		}
	}
</style>