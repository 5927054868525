<template>
	<div class="container">
		<!-- <div class="left_icon"><van-icon name="arrow-left" color="#fff" @click="handleClick"/></div> -->
		<div class="container_title" v-if="type==2"><img src="../../assets/img/teacher_top.png" alt=""></div>
		<div class="container_title" v-if="type==1"><img src="../../assets/img/student_top.png" alt=""></div>
		<div class="container_box">
			<infoForm @formClick="formClick" :btn-flag="btnFlag" :level-type="type" for-type="2" @btnInfo="btnInfo"></infoForm>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;display: block;padding: 25px 0px;margin: auto;" alt="">
	</div>
</template>

<script>
	import infoForm from '@/components/infoForm'
	import {
		areaList
	} from '@vant/area-data'
	import {
		getInfo,
		getRegister,
		profileData,
		uploadData
	} from '@/api'
	export default {
		name: "info",
		data() {
			return {
				areaList,
				value: '',
				status: false,
				show: false,
				cascaderValue: '',
				btnFlag: false,
				type: '',
			}
		},
		components: {
			infoForm
		},
		created() {
			this.type = this.$route.query.type;
		},
		methods: {
			onOpen() {
				this.show = true
			},
			onClose() {
				console.log(this)
				this.status = false
				this.show = false
			},
			handleClick() {
				this.$router.go(-1)
			},
			formClick(e,type) {
				if (type == 'change') {
					profileData(e).then(res => {
						if (res.data.code == 0) {
							this.$toast.fail(res.data.msg)
						} else {
							this.$toast.success(res.data.msg)
							setTimeout(() => {
								this.$router.replace({
									name: 'notice',
									query: {
										type: this.type
									}
								})
							}, 500)
						}
					})
				}else{
					getRegister(e).then(res => {
						if (res.data.code == 0) {
							this.$toast.fail(res.data.msg)
						} else {
							this.$toast.success(res.data.msg)
							setTimeout(() => {
								this.$router.replace({
									name: 'notice',
									query: {
										type: this.type
									}
								})
							}, 500)
						}
					})
				}
			},
			btnInfo(e) {
				if (e == 0) {
					this.btnFlag = false
				}
				if (e == 1) {
					this.btnFlag = true
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	html {
		background-color: #50D8D6;
	}

	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		background: url(../../assets/img/allbanner.png) no-repeat center 0;
		background-size: cover;
		.container_title{
			width: 100%;
			img{
				width: 80%;
			}
		}
		.left_icon {
			box-sizing: border-box;
			padding-top: 20px;
		}

		&_title {
			box-sizing: border-box;
			width: 100%;
			padding: 55px 0px 20px 0px;
			text-align: center;
			font-size: 55px;
			color: #fff;
			letter-spacing: 0.3em;
		}

		&_box {
			padding: 25px 30px 40px;
			width: 650px;
			box-sizing: border-box;
			border-radius: 25px;
			background-color: #fff;
			margin: auto;

			&_item {
				display: flex;
				align-items: center;
				margin-bottom: 38px;

				.item_label {
					font-size: 25px;
					font-weight: bold;
				}

				img {
					width: 52px;
					height: 52px;
				}

				.item {
					display: flex;
					align-items: center;

					.inp {
						width: 455px;
						height: 43px;
						border: 1PX solid #969495;
						border-radius: 20px;
						padding-left: 10px;
						color: #969495;
						font-size: 26px;
					}

					.item_border {
						font-size: 28px;
						border: 1PX solid #969495;
						color: #969495;
						border-radius: 10px;
						padding: 10px 85px 10px 25px;
						margin-right: 24px;
						box-sizing: border-box;
					}

				}
			}
		}
	}
</style>